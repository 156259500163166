/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/prism-coldark-cold.css"
import "./src/styles/global.css"

const now = new Date().getHours()
if (now >= 0 && now < 11) {
  console.log("%cおはようございます🌞", "color:#0ea5e9;font-size:5em;")
} else if (now >= 11 && now < 18) {
  console.log("%cこんにちは( ﾟДﾟ)⊃旦", "color:#ff6600;font-size:5em;")
} else if (now >= 18 && now < 23) {
  console.log("%cこんばんは😪", "color:#4f46e5;font-size:5em;")
}
