import { GrinningFace, ZanyFace, DottedLineFace, SmilingFaceWithSunglasses, PleadingFace, BackhandIndexPointingRight } from 'react-blobmoji';
import * as React from 'react';
export default {
  GrinningFace,
  ZanyFace,
  DottedLineFace,
  SmilingFaceWithSunglasses,
  PleadingFace,
  BackhandIndexPointingRight,
  React
};